<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-start px-3 mb-4">
        <div class="col-md-6"><h2>Pengurusan Tetapan</h2></div>
      </div>
    </div>

      <div class="box">
        <div v-if="!loading">
        <div class="row">
        <div class="d-flex row">
          <div class="col-6 col-md-4 mb-4">
            <div class="">
              <u><h5>PEKTA</h5></u>
              <label class="form-label">Buka permohonan PEKTA</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="pektaSettingForm.pekta_application_enable" @change="editMode = true">
              </div>
            </div>
          </div>

          <div class="col-6 col-md-4 mb-4">
            <div class="">
              <u><h5>KATAM</h5></u>
              <label class="form-label">Buka permohonan KATAM</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="pektaSettingForm.katam_application_enable" @change="editMode = true">
              </div>
            </div>

            <div class="col-6 mt-4">
              <div class="mb-3">
                <div class="mb-1">
                  <label class="form-label">Tarikh mula KATAM</label>
                </div>
                <div>
                  <date-picker v-model="pektaSettingForm.katam_start_date" type="date" valueType="format" placeholder="Pilih tarikh" @change="editMode = true"/>
                </div>
              </div>
            </div>

            <div class="col-6 mb-4 mt-4">
              <div class="mb-3">
                <div class="mb-1">
                  <label class="form-label">Tarikh luput KATAM</label>
                </div>
                <div>
                  <date-picker v-model="pektaSettingForm.katam_expiry_date" type="date" valueType="format" placeholder="Pilih tarikh" @change="editMode = true"/>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-4 mb-4">
            <div class="">
              <u><h5>PIHTAS</h5></u>
              <label class="form-label">Buka permohonan PIHTAS</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="pektaSettingForm.pihtas_application_enable" @change="editMode = true">
              </div>
            </div>
          </div>

        </div>
      </div>
        <div class="d-flex justify-content-end align-items-center">
          <button v-if="editMode == true" class="btn btn-primary" @click="submitSave()">Simpan</button>
          <button v-else class="btn btn-secondary">Simpan</button>
        </div>
      </div>
      <div v-else class="">
        <span>Loading ...</span>
      </div>
    </div>
  </div>
</template>

<script>
import API from '../../utils/API';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
  data() {
    return {
      pektaSettingForm: {
        pekta_application_enable: null,
        pihtas_application_enable: null,
        katam_application_enable: null,
        katam_start_date: null,
        katam_expiry_date: null
      },
      editMode: false,
      loading: false
    }
  },
  watch: {
    // pektaSettingForm: {
    //   handler(){
        
    //   },
    //   deep: true
    // }
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async submitSave(){
      const res = await API.put('pekta-setting', { data: this.pektaSettingForm })

      this.editMode = false
    }
  },
  async mounted() {
    this.loading = true
    const res = await API.get('pekta-setting')
    if(res.data && res.data.data){
      const setting = res.data.data
      this.pektaSettingForm.pekta_application_enable = setting.attributes.pekta_application_enable
      this.pektaSettingForm.pihtas_application_enable = setting.attributes.pihtas_application_enable
      this.pektaSettingForm.katam_application_enable = setting.attributes.katam_application_enable
      this.pektaSettingForm.katam_start_date = setting.attributes.katam_start_date
      this.pektaSettingForm.katam_expiry_date = setting.attributes.katam_expiry_date
    }
    this.loading = false
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.box {
  background-color: #fff;
  padding: 30px 25px;
}

</style>